/** 1. Imports **/
import React, { useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby-plugin-react-i18next'

import { getRandomEmoji } from '../../providers/utils/getRandomEmoji'
import { useStateHandler } from '../../providers/hooks/useStateHandler'
import { mainTopics } from '../../../gatsby-config'

/** 2. Types **/
type Props = {
  className?: string
  language: string
  emoji: string[]
}

type ContainerProps = {
  className?: string
  language: string
}

/** 3. Base component **/
const Component = ({ className, language, emoji }: Props) =>
  useMemo(
    () => (
      <div className={className}>
        <div className={'side-title'}>Main Topics</div>
        <div>
          {mainTopics.map((tag, i) => (
            <Link key={tag} to={`/?tag=${tag}`} language={language}>
              <p>
                #{tag}
                {emoji[i] || ''}
              </p>
            </Link>
          ))}
        </div>
      </div>
    ),
    [emoji]
  )

/** 4. Styled component **/
const StyledComponent = styled(Component)`
  margin-bottom: 40px !important;

  > div:last-child {
    display: flex;
    flex-wrap: wrap;
    column-gap: 8px;

    line-height: 2.4;
    font-size: 1.8rem;
    font-weight: 500;
  }
`

/** 5. Container **/
export const MainTopics = (props: ContainerProps) => {
  const emojiHandler = useStateHandler<string[]>([])

  useEffect(() => {
    emojiHandler.setValue(getRandomEmoji())
  }, [])

  return <StyledComponent {...props} emoji={emojiHandler.value} />
}
